<template>
    <div class="w-full">
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <div v-else-if="question_set" :id="'form-set-' + question_set.ID">
            <h3 v-if="hideTitle !== true" :id="'form-set-' + question_set.ID">{{question_set.FormQuestionSetTitle}}</h3>
            <div style="min-height:2px" class="mb-2">
				<v-progress-linear v-if="saving" height="2px" :color="$props.color" indeterminate></v-progress-linear>
			</div>
            <v-form lazy-validation v-model="valid" ref="form" :disabled="saving">
                <div class="form-set-field">
                    <v-row wrap v-if="question_set.FormQuestions">
                        <v-col
                            v-for="(question, idx) in question_set.FormQuestions"
                            :key="getKey(idx, question)"
                            :cols="question.FormQuestionConfig.cols"
                            :sm="question.FormQuestionConfig.smCols"
                            :md="question.FormQuestionConfig.mdCols"
                            :lg="question.FormQuestionConfig.lgCols"
                            class="q-fml-question_field-wrapper"
                        >
                            <div v-if="showBlock(question.FormQuestionConfig)" style="width: 100%">
                                <component
                                    :is="'fml-' + question.FormQuestionConfig.type"
                                    v-model="value.FormResponseAnswers[question.ID]"
                                    :question="question"
                                    :agent-code.sync="AgentCode"
                                    :disable-field="disableField || saving"
                                    @value-changed="triggerAutoSaveTimer"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <slot name="right" />
                </div>

                <v-row v-if="!hideSubmitButton">
                    <v-col cols="12" :class="lastSet ? 'text-center' : 'text-right'">
                        <slot 
                            name="controls"
                            v-bind="{
                                disabled: disableField,
                                saving,
                                saveResponse,
                                lastSet,
                            }"
                        >
                            <v-btn :disabled="disableField" :x-large="lastSet ? true : false" :color="lastSet ? 'primary' : 'grey lighten-2'" large @click="saveResponse()">
                                {{ submit_button_label }}
                                <v-progress-circular size="20" indeterminate right v-if="saving"></v-progress-circular>
                            </v-btn>
                        </slot>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div v-else>Whooops! That form is not longer available!</div>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import FmlTextField from './QuestionTypes/FmlTextField.vue'
import FmlTextArea from './QuestionTypes/FmlTextArea.vue'
import FmlCheckbox from './QuestionTypes/FmlCheckbox.vue'
import FmlSelect from './QuestionTypes/FmlSelect.vue'
import FmlTags from './QuestionTypes/FmlTags.vue'
import FmlRadio from './QuestionTypes/FmlRadio.vue'
import FmlSwitch from './QuestionTypes/FmlSwitch.vue'
import FmlAgentLookup from './QuestionTypes/FmlAgentLookup.vue'
import FmlLeadLookup from './QuestionTypes/FmlLeadLookup.vue'
import FmlRepeatable from './QuestionTypes/FmlRepeatable.vue'

/*
This item can be used as a stand alone form for more programmatic implementations.
Listen for these events...
'input' = the response data / question answers was updated.
'submit' = the user clicked the submit button
 */

const fiveMinutesInMilliseconds = 5 * 60 * 1000

export default {
    props: {
        'questionSet': {},
        'value': {},
        'slug': {},
        'questionSetIndex': {},
        'lastSet': {},
        'hideTitle': {},
        'appendQueryString': {},
        redirectOnSave: { type: Boolean, default: true },
        formType: { type: String },
        color: { type: String },
        autoSave: { type: Boolean, default: false },
        autoSaveDelay: { type: Number, default: fiveMinutesInMilliseconds },
        disableField: { type: Boolean, default: false },
        hideSubmitButton: { type: Boolean, default: false },
        // TODO: Ideally submit shouldn't happen in UI component, but in the parent component
        /** Used to preview form, so we don't need to submit response if set to true */
        doNotSubmit: { type: Boolean, default: false },
    },
    data: () => ({
        loaded: false,
        loadedQuestionSet: null,
        saving: false,
        valid: false,
        loading: false,
        AgentCode: null,
        autoSaveTimeout: null,
    }),
    mounted: function() {
        //if a question set was not passed then we need to load one
        if (typeof this.questionSet == 'undefined' || this.questionSet == null) {
            var response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            this.loadQuestionSet(response_id)
            return // we don't want to load the response... it will come with the question set data.
        }
        if (typeof this.value == 'undefined' || this.value == null) {
            this.loadFormResponseTemplate()
        }
    },
    computed: {
        'question_set': function() {
            if (typeof this.questionSet != 'undefined' || this.questionSet != null) {
                return this.questionSet
            }
            if (this.loadedQuestionSet != null) {
                return this.loadedQuestionSet;
            }
            return false
        },
        'submit_button_label': function() {
            if (this.lastSet === true) {
                var label = "Submit";
            } else {
                label = this.formType === 'Quiz' ? 'Proceed' : "Save";
            }
            if (typeof this.question_set.FormQuestionSetConfig == 'undefined') {
                return label;
            }
            return this.question_set.FormQuestionSetConfig.saveButtonLabel ? this.question_set.FormQuestionSetConfig.saveButtonLabel : label
        }
    },
    methods: {
        //using the slug... we will load the questions
        loadQuestionSet: function(response_id) {
            var g = this
            g.loaded = false
            g.loading = true
            g.$set(g, "loadedQuestionSet", null);
            QuilityAPI.getFormWithSlug(this.slug, response_id)
                .then(function(data) {
                    g.loading = false
                    g.$set(g, "loadedQuestionSet", data.FormQuestionSets[g.questionSetIndex]);
                    //g.$set(g, "value", data.FormResponse);
                    g.$emit('input', data.FormResponse)
                    //g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need the form response. this requires the form id to be set.
        loadFormResponse: function(response_id) {
            var g = this
            g.loaded = false
            g.loading = true
            var id = response_id == "new" ? null : response_id;
            QuilityAPI.getFormResponse(this.question_set.FormID, id)
                .then(function(data) {
                    g.loading = false
                    //g.$set(g, "value", data);
                    g.$emit('input', data)
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need a blank template.
        loadFormResponseTemplate: function() {
            var g = this
            g.loaded = false
            g.loading = true
            QuilityAPI.getFormResponseTemplate(this.question_set.FormID)
                .then(function(data) {
                    g.loading = false
                    //g.$set(g, "value", data);
                    g.$emit('input', data)
                    //g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        getKey: function(ix, question) {
            return ix + "-" + question.key + '-' + question.ID + '-' + this.$route.query.form_response_id
        },
        async saveResponse(autoSaving = false) {
            if (!this.$refs.form.validate()) {
                this.goToTop();
                this.showError("Please correct invalid fields.");
                return false;
            }

            const response = { ...this.value };
            if (this.lastSet) {
                response.SubmittedInd = true
            }

            if (this.doNotSubmit) {
                if (!autoSaving) {
                    this.$emit('saved', response)
                }
                return true
            }

            clearTimeout(this.autoSaveTimeout)
            this.autoSaveTimeout = null

            if (!autoSaving) {
                this.saving = true
            }

            return QuilityAPI.saveFormResponse(this.question_set.FormID, response)
                .then((data) => {
                    this.saving = false
                    if (data.error && !autoSaving) {
                        this.showError(data.text)
                        return false
                    }
                    if (typeof data == 'undefined' && !autoSaving) {
                        this.showError("Whoops! There was a problem submitting the form!")
                        return false
                    }

                    if (autoSaving) {
                        // this code is needed to avoid overriding new user changes
                        Object.values(data.FormResponseAnswers).forEach((form) => {
                            form.AnswerContent = this.value.FormResponseAnswers[form.FormQuestionID]?.AnswerContent
                        })
                    }
                    this.$emit('input', data)
                    this.$emit('saved', data, autoSaving)

                    if (response.SubmittedInd) {
                        this.$emit('submitted', data)
                    }

                    if (this.redirectOnSave && !autoSaving) {
                        this.$router.replace({
                            query: {
                                "form_response_id": data.ID,
                                ...this.appendQueryString
                            }
                        })
                    }
                    return true
                })
                .catch(() => {
                    if (!autoSaving) {
                        this.saving = false
                        this.showError("Whoops! Can't find that form!")
                    }
                });
        },
        //slides the form to the top
        goToTop: function() {
            this.$vuetify.goTo('#form-set-' + this.question_set.ID, {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },

        // This method can be used outside the component via 'ref'
        triggerAutoSaving() {
            if (!this.autoSaveTimeout) { return }

            this.saveResponse(true)
        },
        triggerAutoSaveTimer() {
            if (!this.autoSave) { return }
            if (this.saving) { return }
            if (this.disableField) { return }
            if (this.autoSaveTimeout) { return }

            this.autoSaveTimeout = setTimeout(() => {
                this.triggerAutoSaving()
            }, this.autoSaveDelay)
        },
    },
    watch: {
        '$route.query.form_response_id': function(newV, oldV) {
            //if (typeof newV != 'undefined' && typeof oldV == 'undefined') {
            //return
            //}
            //we want to load a specific response id...
            if (typeof newV != 'undefined') {
                //looks like we never got a slug... so we will pull the response directly with the response id and form id.
                if (typeof this.slug == 'undefined') {
                    this.loadFormResponse(newV);
                } else {
                    //we have a slug... the slug endpoint handles this for us...
                    this.loadQuestionSet(newV);
                }
                return
            }
            if (typeof newV == 'undefined') {
                if (typeof this.slug == 'undefined') {
                    //looks like we never got a slug... so we will pull a blank response template
                    this.loadFormResponseTemplate('new');
                } else {
                    //we have a slug... the slug endpoint handles this for us...
                    this.loadQuestionSet('new');
                }
            }
            return
        },
        'slug': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.loadQuestionSet()
            }
        },
        loaded(val) {
            if (val) {
                if (this.$route.hash) {
                    const element = document.querySelector(this.$route.hash)

                    if (element) {
                        const top = element.getBoundingClientRect().top + window.pageYOffset - 100
                        window.scrollTo({ top })
                    }
                }
            }
        }
    },
    components: {
        FmlTextField,
        FmlCheckbox,
        FmlSelect,
        FmlTags,
        FmlRadio,
        FmlSwitch,
        FmlAgentLookup,
        FmlLeadLookup,
        FmlRepeatable,
        FmlTextArea
    },
}

</script>

<style lang="scss" scoped>
.fml-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.fml-edit-btn {
    padding-left: 15px;
}

.w-full {
    width: 100%;
}

.form-set-field {
    display: flex;
    .q-fml-question_field-wrapper {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .form-set-field { flex-direction: column; }
}
</style>
